<template>
  <v-container class="py-5" style="max-width: 90vw">
    <v-row>
      <v-col cols="9">
        <div style="display: flex" class="justify-space-between align-center">
          <div>
            <TitleComponent title="Tambah Aset" />
            <breadcrumbs-component :crumbs="crumbs" />
          </div>
        </div>
        <div class="mt-10">
          <div>
            <v-container
              style="border-radius: 20px; border: 1px #abd1c6 solid"
              class="px-7 py-7"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <div
                    style="
                      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
                      border-radius: 15px;
                    "
                  >
                    <div
                      style="
                        background: #bee3d9;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                      class="d-flex align-center"
                    >
                      <h3
                        style="
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                        class="px-3 py-2"
                      >
                        Kategori Utama
                      </h3>
                    </div>
                    <div>
                      <v-select
                        class="px-3 py-2"
                        placeholder="Pilih Kategori Utama"
                        v-model="asset.asset_category"
                        :items="[
                          'Tanah',
                          'Gedung dan Bangunan',
                          'Jalan, Irigasi, dan Jaringan',
                          'Mesin dan Peralatan',
                          'Aset Tak Berwujud',
                          'Tanah',
                          'Gedung dan Bangunan',
                          'Mesin dan Peralatan',
                          'Aset Tak Berwujud',
                        ]"
                        dense
                      ></v-select>
                    </div>
                  </div>
                </v-col>
                <template v-for="property in properties">
                  <v-col cols="12" md="6" :key="property.key">
                    <card-detail-component
                      :label="property.label"
                      :value="asset[property.key]"
                      :editable="isEditable"
                      v-model="asset[property.key]"
                      :placeholder="property.placeholder"
                    />
                  </v-col>
                </template>
                <v-col cols="12" md="6">
                  <div
                    style="
                      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
                      border-radius: 15px;
                    "
                  >
                    <div
                      style="
                        background: #bee3d9;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                      class="d-flex align-center"
                    >
                      <h3
                        style="
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                        class="px-3 py-2"
                      >
                        Tanggal Perolehan
                      </h3>
                    </div>
                    <div class="px-3 py-2">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="asset.date_of_acquisition"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            placeholder="Pilih Tanggal Perolehan"
                            v-model="asset.date_of_acquisition"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="asset.date_of_acquisition"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(asset.date_of_acquisition)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
                <!-- Upload Image -->
                <v-col cols="12" md="6">
                  <div
                    style="
                      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
                      border-radius: 15px;
                    "
                  >
                    <div
                      style="
                        background: #bee3d9;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                      class="d-flex align-center"
                    >
                      <h3
                        style="
                          font-size: 16px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                        class="px-3 py-2"
                      >
                        Foto Aset
                      </h3>
                    </div>
                    <div>
                      <div class="mx-3 my-2 py-2">
                        <label id="label-image" for="file-upload">+</label>
                        <input
                          type="file"
                          id="file-upload"
                          multiple
                          @change="handleFileUpload"
                          accept="image/*"
                        />
                        <div class="image-container">
                          <div
                            v-for="(image, index) in images"
                            :key="index"
                            class="image-item"
                          >
                            <img
                              :src="image.previewUrl"
                              alt="Preview"
                              class="pic"
                              @click="showImage(index)"
                            />
                            <button
                              class="remove-button"
                              @click="removeImage(index)"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <template v-for="(item, index) in customItems">
                  <v-col cols="12" md="6" :key="index">
                    <card-detail-component
                      :label="item.information_name"
                      :value="item.information_value"
                      :editable="isEditable"
                      v-model="item.information_value"
                      :isCustom="true"
                      @closeCard="removeCustomItem(index, item.id)"
                    />
                  </v-col>
                </template>
                <v-col cols="12" md="6">
                  <v-dialog v-model="dialog" max-width="434px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        small
                        @click="addCard"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-form ref="form" v-model="valid">
                        <v-card-title class="mb-5">
                          Tambah Keterangan
                          <v-spacer></v-spacer>
                          <v-btn icon @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <h4>Nama Keterangan</h4>
                          <v-text-field
                            class="py-0"
                            required
                            v-model="newCard.label"
                            :rules="[
                              !!newCard.label ||
                                'Nama Keterangan tidak boleh kosong',
                            ]"
                          ></v-text-field>
                          <h4>Isi Keterangan</h4>
                          <v-text-field
                            class="py-0"
                            required
                            v-model="newCard.value"
                            :rules="[
                              !!newCard.value ||
                                'Isi Keterangan tidak boleh kosong',
                            ]"
                          >
                          </v-text-field>
                          <div class="text-center mt-auto">
                            <v-btn
                              class="white--text text-capitalize"
                              color="#004643"
                              width="80%"
                              @click="submitForm"
                              >Simpan</v-btn
                            >
                          </div>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn
                  @click="submitAset()"
                  class="white--text text-capitalize ml-2"
                  color="#004643"
                  >Simpan
                </v-btn>
              </v-row>
            </v-container>
          </div>
          <div></div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card>
        <v-card-text>
          Mohon tunggu
          <v-progress-linear
            indeterminate
            color="green"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import CardDetailComponent from "@/components/CardDetailComponent";

export default {
  components: {
    TitleComponent,
    CardDetailComponent,
    BreadcrumbsComponent,
  },
  methods: {
    doSelectAndShowPopUpMenu(data) {
      this.optionDialog = true;
      this.filterChoices = data.title;
    },
    editAset() {
      this.isEditable = true;
    },
    addCard() {
      if (this.newCard.label && this.newCard.value) {
        this.customItems.push({
          information_name: this.newCard.label,
          information_value: this.newCard.value,
          asset_id: this.$route.params.id,
        });

        this.newCard.label = "";
        this.newCard.value = "";
      }
      console.log(this.customItems);
    },
    closeDialog() {
      this.dialog = false;
    },
    submitForm() {
      this.$refs.form.validate();
      if (this.valid) {
        this.addCard();
        this.closeDialog();
      }
    },
    removeCustomItem(index, id) {
      this.customItems.splice(index, 1);
      if (id) {
        this.$axios
          .delete(`/additional-information/${id}`)
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async submitAset() {
      this.dialogLoading = true;
      const imageUrls = [];
      for (let i = 0; i < this.images.length; i++) {
        const formData = new FormData();
        formData.append("image", this.images[i].file);
        const response = await this.$axios.post("/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        imageUrls.push(response.data.url);
      }
      await this.$axios
        .post(`/assets`, {
          data: { ...this.asset, imageUrls },
          additionalData: this.customItems,
        })
        .then((response) => {
          this.message = response.data.status;
          this.snackbar = true;
          this.dialogLoading = false;
          new Promise((resolve) => setTimeout(resolve, 2000)).then(() =>
            this.$router.push("/aset")
          );
        });
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images.push({
            file: files[i],
            previewUrl: e.target.result,
          });
        };
        reader.readAsDataURL(files[i]);
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
  },
  data: () => ({
    dialogLoading: false,
    selectedImage: null,
    snackbar: false,
    message: "",
    timeout: 3000,
    filterChoices: "",
    optionDialog: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    information: "",
    properties: [
      {
        label: "Kode Aset",
        key: "asset_code",
        placeholder: "Masukkan Kode Aset",
      },
      {
        label: "Nama Aset",
        key: "asset_name",
        placeholder: "Masukkan Nama Aset",
      },
      {
        label: "Lokasi Aset",
        key: "asset_location",
        placeholder: "Masukkan Lokasi Aset",
      },
      {
        label: "Seksi",
        key: "section",
        placeholder: "Masukkan Seksi Aset"
      },
      {
        label: "Koordinat",
        key: "coordinate",
        placeholder: "Masukkan Koordinat (xxxxx,yyyyy)",
      },
      {
        label: "Luas",
        key: "area",
        placeholder: "Masukkan Angka Luas Aset (1234455)",
      },
      {
        label: "Jenis Investasi",
        key: "investation_type",
        placeholder: "Masukkan Jenis Investasi",
      },
      {
        label: "Pemilik",
        key: "asset_owner",
        placeholder: "Masukkan Pemilik Aset",
      },
      {
        label: "Pengembang",
        key: "asset_developer",
        placeholder: "Masukkan Pengembang Aset",
      },
      {
        label: "Operator",
        key: "asset_operator",
        placeholder: "Masukkan Operator Aset",
      },
      {
        label: "Keterangan Tambahan",
        key: "information",
        placeholder: "Masukkan Keterangan Tambahan (Opsional)",
      },
      {
        label: "Harga Perolehan",
        key: "price_of_acquisition",
        placeholder: "Masukkan Harga Perolehan Aset",
      },
    ],
    valid: true,
    isEditable: true,
    newCard: {
      label: "",
      value: "",
    },
    customItems: [],
    dialog: false,
    asset: {
      asset_category: null,
      asset_location: null,
      coordinate: null,
      area: null,
      investation_type: null,
      asset_owner: null,
      asset_developer: null,
      asset_operator: null,
      information: null,
      date_of_acquisition: null,
      price_of_acquisition: null,
    },
    images: [],
    menu: false,
  }),
  computed: {
    crumbs() {
      return [
        {
          text: "Layanan Aset",
          disabled: false,
          href: "/aset",
        },
        {
          text: "Tambahkan Aset",
          disabled: true,
          href: "/aset/create",
        },
      ];
    },
  },
};
</script>

<style>
input[type="file"] {
  display: none;
}

#label-image {
  padding: 10px 16px;
  color: #004643;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  border: 1px solid #004643;
}

#label-image:hover {
  background-color: #004643;
  color: #ffffff;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.image-item {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.pic {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 3.5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
}

.remove-button:hover {
  background-color: #d32f2f;
}
</style>
