<template>
  <div>
    <v-btn
      id="menu-activator"
      color="#004643"
      class="white--text text-capitalize ml-2"
      :disabled="!this.account.scopes.includes(this.scopeConstant.manage_asset)"
    >
      Opsi Lainnya
      <img :src="require('../assets/icons/ion_chevron-up-outline.svg')" />
    </v-btn>
    <v-menu activator="#menu-activator">
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" :value="index">
          <v-list-item-title
            @click="doSelectAndShowPopUpMenu(item)"
            style="cursor: pointer"
            >{{ item.title }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="optionDialog" max-width="434px">
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-card-title class="mb-5">
            {{ filterChoices }}
            <v-spacer></v-spacer>
            <v-btn icon @click="optionDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <h4>Waktu Eksekusi</h4>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <h4>Keterangan</h4>
            <v-text-field
              class="py-0"
              required
              v-model="information"
              :rules="[!!information || 'Isi Keterangan tidak boleh kosong']"
            >
            </v-text-field>
            <template v-if="filterChoices === 'Ditingkatkan'">
              <h4>Nilai Penambahan</h4>
              <v-text-field
                class="py-0"
                required
                type="number"
                step="1"
                v-model.number="additionalValue"
                :rules="[
                  !!additionalValue || 'Isi tidak boleh kosong',
                  (value) =>
                    Number.isInteger(Number(value)) ||
                    'Harus berupa bilangan bulat',
                ]"
              >
              </v-text-field>
              <h4>Sumber Anggaran</h4>
              <v-text-field
                class="py-0"
                required
                v-model="source"
                :rules="[!!source || 'Isi Keterangan tidak boleh kosong']"
              >
              </v-text-field>
            </template>
            <div class="text-center mt-auto">
              <v-btn
                class="white--text text-capitalize"
                color="#004643"
                width="80%"
                @click="submitOption"
                >Simpan</v-btn
              >
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: "Dihibahkan" },
      { title: "Dimusnahkan" },
      { title: "Dilelang" },
      { title: "Diputihkan" },
      { title: "Kerjasama Operasi" },
      { title: "Ditingkatkan" },
    ],
    filterChoices: "",
    optionDialog: false,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    information: "",
    valid: false,
    source: null,
    additionalValue: null,
  }),
  methods: {
    doSelectAndShowPopUpMenu(item) {
      this.filterChoices = item.title;
      this.optionDialog = true;
    },
    submitOption() {
      this.$refs.form.validate();
      if (this.valid) {
        const payload = {
          assetId: this.asset.id,
          assetStatus: this.filterChoices,
          executionDate: this.date,
          information: this.information,
          source: this.source,
          additionalValue: this.additionalValue,
        };
        this.$axios
          .post("/asset-history/" + this.asset.id, payload)
          .then(() => {
            this.$router.push({ name: this.redirect });
          });
      }
    },
  },
  props: {
    account: Object,
    asset: Object,
    scopeConstant: Object,
    redirect: String,
  },
};
</script>

<style></style>
