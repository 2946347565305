<template>
  <div
    class="mb-3"
    style="
      background: #fffffe;
      border-radius: 15px;
      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
    "
  >
    <div
      style="border-bottom: 1px #f9bc60 solid; display: flex"
      class="py-2 px-4 align-center justify-space-between"
    >
      {{ penyebab }}
      <a :href="computedHref" style="height: 24px" v-if="source">
        <img
          :src="require('../assets/icons/chevron-right.svg')"
          style="cursor: pointer"
        />
      </a>
      <a v-if="penyebab.startsWith('Diubah')" @click="handlePopup">
        <img
          :src="require('../assets/icons/chevron-right.svg')"
          style="cursor: pointer"
        />
      </a>
    </div>
    <div style="display: flex" class="align-center px-4 pt-2">
      <v-col cols="6">
        <div>
          <h5>Waktu Kejadian</h5>
          <h5 class="font-weight-regular">{{ waktuKejadian }}</h5>
        </div>
      </v-col>
      <v-col cols="6">
        <div>
          <h5>Waktu Selesai</h5>
          <h5 class="font-weight-regular">
            {{ source ? (waktuSelesai ? waktuSelesai : "-") : waktuKejadian }}
          </h5>
        </div>
      </v-col>
    </div>
    <div style="display: flex" class="align-center px-4">
      <v-col cols="6">
        <div>
          <h5>Sumber Anggaran</h5>
          <h5 class="font-weight-regular">{{ budgetSource || "-" }}</h5>
        </div>
      </v-col>
      <v-col cols="6">
        <div>
          <h5>Nilai Penambahan</h5>
          <h5 class="font-weight-regular">
            {{ additionalValue ? formatRupiah(additionalValue) : "-" }}
          </h5>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import { formatRupiah } from "@/plugins/helpers";
export default {
  methods: {
    formatRupiah,
    handlePopup() {
      this.openPopup(this.historyId, this.historyEditor);
    },
  },
  props: [
    "penyebab",
    "waktuKejadian",
    "waktuSelesai",
    "source",
    "id",
    "historyId",
    "historyEditor",
    "openPopup",
    "budgetSource",
    "additionalValue",
  ],
  computed: {
    computedHref() {
      if (
        this.source?.toLowerCase() === "default" ||
        this.source?.toLowerCase() === "inspeksi"
      ) {
        return `/layanan-pemeliharaan/inisiasi/${this.id}`;
      } else if (
        this.source?.toLowerCase() === "accident" ||
        this.source?.toLowerCase() === "kecelakaan"
      ) {
        return `/lalu-lintas/kecelakaan/${this.id}`;
      } else if (
        this.source?.toLowerCase() === "asset_disruption" ||
        this.source?.toLowerCase() === "gangguan aset"
      ) {
        return `/lalu-lintas/gangguan-aset/${this.id}`;
      } else if (
        this.source?.toLowerCase() === "traffic_disturbance" ||
        this.source?.toLowerCase() === 'Gangguan Lalu Lintas'
      ) {
        return `/lalu-lintas/gangguan-lalu-lintas/${this.id}`;
      } else if (
        this.source?.toLowerCase() === "SPECIAL_ACCIDENT" ||
        this.source?.toLowerCase() === 'Kejadian Khusus'
      ) {
        return `/lalu-lintas/kejadian-khusus/${this.id}`;
      } else {
        return "#";
      }
    },
  },
};
</script>

<style></style>
