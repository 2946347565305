<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center">
      <div>
        <title-icon-component
          title="Mesin dan Peralatan"
          image="mesin_peralatan.svg"
        />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn class="text-capitalize" href="/aset/mesin-peralatan/riwayat"
          >Lihat Riwayat</v-btn
        >
<!--        <v-btn-->
<!--          @click="download"-->
<!--          class="white&#45;&#45;text text-capitalize ml-2"-->
<!--          color="#004643"-->
<!--          >Download</v-btn-->
<!--        >-->
      </div>
    </div>

    <table-component
      v-if="!isLoading"
      class="mt-5"
      :headers="tableHeaders"
      :items="computedAssets"
      :enable-download="true"
      file-name="aset_mesin_dan_peralatan.xlsx"
      :limit="limit"
      :page="page"
      @update:limit="updateLimit"
      @update:page="updatePage"
    />
    <v-skeleton-loader
      v-else
      type="table"
    />
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import TitleIconComponent from "@/components/TitleIconComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
export default {
  components: {
    TableComponent,
    TitleIconComponent,
    BreadcrumbsComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.page = parseInt(this.$route.query.page) || 1;
      this.limit = parseInt(this.$route.query.limit) || 20;

      if (this.$route.query?.page?.toString() !== this.page?.toString() || this.$route.query?.limit?.toString() !== this.limit?.toString()) {
        await this.$router.push({ query: { ...this.$route.query, page: this.page, limit: this.limit } 
      })}

      await this.$axios
        .get("/all-assets?category=4")
        .then((response) => {
          console.log((this.assets = response.data.data.assets));
          this.assignHeaders();
        });
      
      this.isLoading = false;
    },
    assignHeaders() {
      const headers = [
        {
          text: "ID System",
          value: "id",
        },
        {
          text: "Nama Aset",
          align: "start",
          value: "asset_name",
        },
        {
          text: "Kode Aset",
          value: "asset_code",
        },
        {
          text: "Lokasi Aset",
          value: "asset_location",
        },
        { text: "Seksi", value: "section"},
        { text: "Pemilik", value: "asset_owner" },
        { text: "Pengembang", value: "asset_developer" },
        { text: "Operator", value: "asset_operator" },
        { text: "Keterangan Tambahan", value: "information" },
        { text: "Harga Perolehan", value: "price_of_acquisition" },
        { text: "Tanggal Perolehan", value: "date_of_acquisition" },
      ];

      if (this.assets.length > 0) {
        this.assets[0]['additional_properties'].forEach((item) => {
          headers.push({text: item.name, value: item.name});
        });
      }

      headers.push({ text: "Foto", value: "foto", sortable: false, filterable: false },)

      headers.push({
        text: "",
        value: "arrow",
        sortable: false,
        filterable: false,
        width: "50px",
        align: "center",
      });

      this.tableHeaders = headers;
    },
    async download() {
      const queryParams = {
        category: "4",
      };

      try {
        const response = await this.$axios.get("/download-assets-by-category", {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        a.href = url;
        a.download = `Aset Mesin dan Peralatan.xlsx`;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    updateLimit(newLimit) {
      if (this.limit !== newLimit) {
        this.limit = newLimit;
        this.$router.push({ query: { ...this.$route.query, limit: this.limit, page: 1 } });
      }
    },
    updatePage(newPage) {
      if (this.page !== newPage) {
        this.page = newPage;
        this.$router.push({ query: { ...this.$route.query, page: this.page } });
      }
    },
  },
  computed: {
    computedAssets() {
      const computedAssets = []

      this.assets.forEach((asset) => {
        const computedAsset = {
          ...asset,
          href: "/aset/mesin-peralatan/" + asset.id,
        }

        asset['additional_properties'].forEach((item) => {
          computedAsset[item.name] = item.value;
        })
        computedAsset['foto'] = asset.image_urls[0] || '',
        computedAssets.push(computedAsset);
      });
      return computedAssets;
    },
  },
  data: () => ({
    snackbar: false,
    message: "",
    timeout: 3000,
    assets: [],
    crumbs: [
      {
        text: "Layanan Aset",
        disabled: false,
        href: "/aset",
      },
      {
        text: "Mesin dan Peralatan",
        disabled: true,
        href: "/aset/mesin-peralatan",
      },
    ],
    tableHeaders: [],
    page: 1,
    limit: 20,
    isLoading: true
  }),
};
</script>

<style></style>
