<template>
  <div>
    <v-btn
      @click="dialog = true"
      class="white--text text-capitalize"
      color="#004643"
    >
      Unggah Foto
    </v-btn>

    <v-dialog v-model="dialog" width="auto">
      <v-card class="px-10 py-10">
        <v-layout row wrap justify-space-between align-center>
          <h1 style="font-size: 1.5rem; font-weight: 700">Unggah Foto Aset</h1>
          <img
            style="cursor: pointer"
            @click="dialog = false"
            :src="require('../assets/icons/ph_x-bold.svg')"
          />
        </v-layout>
        <div
          class="dropzone-container mt-10"
          @dragover.prevent="dragover"
          @dragleave.prevent="dragleave"
          @drop.prevent="drop"
        >
          <input
            type="file"
            name="file"
            id="fileInput"
            class="hidden-input"
            @change="onChange"
            ref="fileInput"
            accept="image/*"
            multiple
          />

          <label
            for="fileInput"
            class="file-label"
            style="color: #abb5be; font-size: 16px; width: 50vw"
          >
            <div v-if="isDragging">Drag files disini</div>
            <div
              v-else-if="selectedFiles.length === 0"
              style="display: flex; flex-direction: column"
              class="justify-center align-center"
            >
              <img
                :src="require('../assets/icons/material-symbols_folder.svg')"
                style="width: 32px; height: auto"
                class="mb-1"
              />
              <div style="color: #abb5be; font-size: 16px">
                <u style="color: #004643; font-size: 16px">Cari</u> atau drag
                files untuk unggah foto aset Anda disini
              </div>
            </div>
            <div
              v-if="selectedFiles.length > 0"
              class="selected-files-container"
            >
              <div class="preview-container">
                <div
                  v-for="(file, index) in selectedFiles"
                  :key="index"
                  class="preview-card"
                >
                  <img
                    :src="file.preview"
                    alt="Preview"
                    class="preview-image"
                  />
                  <v-btn
                    icon
                    small
                    @click.prevent="removeFile(index)"
                    class="remove-btn"
                  >
                    <v-icon small color="red">mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="action-buttons">
                <v-btn
                  color="#004643"
                  class="ma-2 white--text"
                  @click="$refs.fileInput.click()"
                  :disabled="isUploading"
                >
                  Add More
                  <v-icon right dark>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  color="green"
                  class="ma-2 white--text"
                  @click="uploadFiles"
                  :loading="isUploading"
                  :disabled="isUploading"
                >
                  Upload
                  <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </div>
            </div>
          </label>
        </div>
        <v-overlay :value="isUploading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbar.show == 'green' ? 'green' : 'white'"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    isDragging: false,
    selectedFiles: [],
    isUploading: false,
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  }),
  methods: {
    dragover() {
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      this.isDragging = false;
      this.addFiles(e.dataTransfer.files);
    },
    onChange(e) {
      this.addFiles(e.target.files);
      e.target.value = "";
    },
    addFiles(files) {
      for (let file of files) {
        if (file.type.startsWith("image/")) {
          this.selectedFiles.push({
            file: file,
            preview: URL.createObjectURL(file),
          });
        }
      }
    },
    removeFile(index) {
      URL.revokeObjectURL(this.selectedFiles[index].preview);
      this.selectedFiles.splice(index, 1);
      this.$refs.fileInput.value = "";
    },
    clearSelectedFiles() {
      this.selectedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
      this.selectedFiles = [];
    },
    async uploadFiles() {
      this.isUploading = true;
      try {
        await Promise.all(
          this.selectedFiles.map(async (file) => {
            const formData = new FormData();

            formData.append("image", file.file);

            await this.$axios.post("/upload-image?is_insert=True", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          })
        );

        this.clearSelectedFiles();
        this.dialog = false;
        this.showNotification("Files uploaded successfully!", "white");
      } catch (error) {
        console.error("Upload failed:", error);
        this.showNotification("Upload failed. Please try again.", "error");
      } finally {
        this.isUploading = false;
      }
    },
    showNotification(text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
};
</script>

<style scoped>
.selected-files-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 15px;
}

.preview-card {
  position: relative;
  width: 100px;
  height: 100px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.remove-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: white !important;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
</style>
