export function formatRupiah(angka) {
    if (angka == null) {
        angka = 0
    }

    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    }).format(angka)
}

export function formatKilometer(km) {
    if (km) {
        let kmStr = km.toString();

        if (isNaN(kmStr) && !kmStr.startsWith("KM")) {
            return kmStr;
        }

        const hasKmPrefix = kmStr.startsWith("KM");
        if (hasKmPrefix) {
            kmStr = kmStr.slice(2).trim();
        }

        if (kmStr.length < 5) {
            kmStr = kmStr.padStart(2, '0') + '+000';
        } else {
            const mainPart = kmStr.slice(0, -3);
            const lastThree = kmStr.slice(-3);
            kmStr = mainPart + '+' + lastThree;
        }

        return hasKmPrefix ? 'KM ' + kmStr : kmStr;
    }
    return '';
}

