var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"items-per-page":20,"footer-props":{
    'items-per-page-options': [5, 10, 15, 20],
  },"hide-default-footer":_vm.hideFooter},scopedSlots:_vm._u([{key:`item.pilih`,fn:function({ item }){return [_c('popup-component',{attrs:{"text":"Pilih"},on:{"dialog-closed":function($event){_vm.step = 1}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('v-card',[_c('v-card-title',{staticClass:"text-h6"}),_c('v-card-text',[_c('v-container',{staticClass:"px-4"},[(_vm.step === 1)?[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Re-kategorisasi")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('h4',{staticClass:"subtitle-1 mb-2"},[_vm._v("Nilai Penambahan")]),_c('v-text-field',{attrs:{"dense":"","required":"","type":"number","step":"1","rules":[
                        !!_vm.additionalValue || 'Isi tidak boleh kosong',
                        (value) =>
                          Number.isInteger(Number(value)) ||
                          'Harus berupa bilangan bulat',
                      ]},model:{value:(_vm.additionalValue),callback:function ($$v) {_vm.additionalValue=_vm._n($$v)},expression:"additionalValue"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('h4',{staticClass:"subtitle-1 mb-2"},[_vm._v("Sumber Anggaran")]),_c('v-text-field',{attrs:{"dense":"","required":"","rules":[
                        !!_vm.budgetSource || 'Isi Keterangan tidak boleh kosong',
                      ]},model:{value:(_vm.budgetSource),callback:function ($$v) {_vm.budgetSource=$$v},expression:"budgetSource"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"#004643"},on:{"click":_vm.nextStep}},[_vm._v(" Simpan ")])],1)],1)]:(_vm.step === 2)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Apakah anda yakin memilih aset ini?")])])],1),_c('v-row',{staticClass:"my-0 mt-2 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v("Aset: "+_vm._s(item.asset_code))])])],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" Nilai Penambahan: "+_vm._s(_vm.additionalValue)+" ")])])],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" Sumber Anggaran: "+_vm._s(_vm.budgetSource)+" ")])])],1)]:_vm._e()],2)],1),(_vm.step === 2)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.step = 1;
              slotProps.closeDialog();
              _vm.submit(item);}}},[_vm._v(" Ya ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.step = 1;
              slotProps.closeDialog();}}},[_vm._v(" Tidak ")])],1):_vm._e()],1)]}}],null,true)})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }