<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center mb-7">
      <div>
        <title-component :title="title" />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn
          class="text-capitalize"
          outlined
          color="red"
          @click="deleteLaporan"
          >Hapus Laporan</v-btn
        >
        <v-btn
          class="white--text text-capitalize ml-2"
          color="#004643"
          @click="download"
          >Download</v-btn
        >
      </div>
    </div>
    <v-row>
      <v-col cols="6" height="100%">
        <card-photo-group-component :images="specialIncidents?.image_urls" />
        <v-card class="mt-5 px-8 py-8 rounded-xl">
          <h5>Waktu Kejadian</h5>
          <p class="body-2 mt-1">{{ specialIncidents?.incident_time }}</p>
          <h5>Shift</h5>
          <p class="body-2 mt-1">{{ specialIncidents?.shift }}</p>
          <h5>Lokasi Kejadian</h5>
          <p class="body-2 mt-1">{{ specialIncidents?.incident_location }}</p>
          <h5>Tipe Kejadian</h5>
          <p class="body-2 mt-1">
            {{ specialIncidents?.special_incident_type }}
          </p>
          <h5>Uraian Kejadian</h5>
          <p class="body-2 mt-1">
            {{ specialIncidents?.incident_description }}
          </p>
          <h5>Cuaca</h5>
          <p class="body-2 mt-1">{{ specialIncidents?.condition_name }}</p>
        </v-card>
      </v-col>
      <v-col cols="6" height="100%">
        <v-card
          v-if="specialIncidents.damaged_assets.length"
          class="px-8 py-8 rounded-xl mb-5"
        >
          <v-row>
            <v-col>
              <h5>Merusak Aset</h5>
              <p class="body-2 mt-1">
                {{
                  specialIncidents["asset_damaged"]
                    ? specialIncidents["asset_damaged"] == true
                      ? "Ya"
                      : "Tidak"
                    : "-"
                }}
              </p>
              <h5>Kerugian</h5>
              <p class="body-2 mt-1">
                {{
                  specialIncidents["compensation"]
                    ? specialIncidents["compensation"] == false
                      ? "Ganti Rugi"
                      : "Tidak Ada Ganti Rugi"
                    : "-"
                }}
              </p>
            </v-col>
            <v-col cols="12">
              <v-carousel class="body-2 mt-1" v-model="model2" height="none">
                <v-carousel-item
                  v-for="(asset, idx) in specialIncidents?.damaged_assets"
                  :key="idx"
                  class="px-8"
                >
                  <damaged-asset-card-component :asset="asset" :idx="idx" />
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="px-8 py-8 rounded-xl">
          <h5>Penanganan Petugas dan Unit Terkait</h5>
          <v-carousel class="body-2 mt-1" v-model="model" height="none">
            <v-carousel-item
              v-for="(officer, idx) in specialIncidents?.officers_and_units"
              :key="idx"
              class="px-8"
            >
              <v-card class="rounded-xl mx-1 my-1">
                <h4
                  style="color: #004643; background-color: #abd1c6"
                  class="py-2 px-8"
                >
                  Petugas & Unit {{ idx + 1 }}
                </h4>
                <div class="px-8 py-2">
                  <h5>Petugas & Unit Terkait</h5>
                  <p class="body-2 mt-1">{{ officer.officer_and_unit_name }}</p>
                  <h5>Tindak Lanjut</h5>
                  <p class="body-2 mt-1">{{ officer.follow_up }}</p>
                </div>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent";
import CardPhotoGroupComponent from "@/components/CardPhotoGroupComponent";
import DamagedAssetCardComponent from "@/components/DamagedAssetCardComponent.vue";

export default {
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      const response = await this.$axios.get(
        `/special-incidents/${this?.$route?.params.id}`
      );
      this.specialIncidents = response.data.data;
      console.log(this.specialIncidents);
    },
    async deleteLaporan() {
      try {
        await this.$axios.delete(
          `/special-incidents/${this?.$route?.params?.id}`
        );
        this.message = "Berhasil menghapus data";
        this.snackbar = true;
        this.$router.push("/lalu-lintas/kejadian-khusus");
      } catch (error) {
        this.message = "Gagal menghapus data";
        this.snackbar = true;
        console.error(error);
      }
    },
    async download() {
      try {
        const response = await this.$axios.get(
          `/download-special-incident/${this?.$route?.params?.id}`
        );
        const mediaType = "data:application/pdf;base64,";
        const base64Data = response.data.pdfData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");

        const fileName = `Detail Kejadian Khusus ${this?.$route?.params?.id}.pdf`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    CardPhotoGroupComponent,
    DamagedAssetCardComponent,
  },
  data: () => ({
    snackbar: false,
    message: "",
    timeout: 3000,
    idData: "",
    dataResponse: null,
    images: [],
    model: 0,
    model2: 0,
    specialIncidents: null,
  }),
  computed: {
    title() {
      return `Detail Kejadian Khusus (${this?.$route?.params?.id})`;
    },
    crumbs() {
      return [
        {
          text: "Layanan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Kejadian Khusus",
          disabled: false,
          href: "/lalu-lintas/kejadian-khusus",
        },
        {
          text: `Detail Kejadian Khusus (${this?.$route?.params?.id})`,
          disabled: true,
          href: `/lalu-lintas/kejadian-khusus/${this?.$route?.params.id}`,
        },
      ];
    },
  },
};
</script>

<style></style>
