<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component :title="title" />
    <breadcrumbs-component :crumbs="crumbs" />

    <chart-component
      title="Total Gangguan Lalu Lintas"
      class="mb-8"
      url-chart="/chart-gangguan-lalu-lintas"
      :parentFunc="updateDate"
    />

    <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10">
      Laporan Gangguan Lalu Lintas
    </h1>

    <v-layout justify-end class="my-3">
      <v-card-title class="ma-0 py-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="ma-0 pa-0"
        ></v-text-field>
      </v-card-title>
      <v-btn
        class="white--text text-capitalize"
        color="#004643"
        width="150"
        height="34"
        @click="dialog = true"
        >Download</v-btn
      >
      <v-dialog v-model="dialog" width="434">
        <v-card>
          <v-card-title class="mb-5">
            <h4>Download Laporan</h4>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-btn
              outlined
              style="max-width: 100%"
              class="btn-wrap-text text-capitalize"
              @click="downloadAs('event', 'XLSX')"
            >
              Data Penanganan Kendaraan Mengalami Gangguan as XLSX
            </v-btn>
            <v-btn
              outlined
              style="max-width: 100%"
              class="btn-wrap-text mt-3 text-capitalize"
              @click="downloadAs('vehicle', 'XLSX')"
            >
              Laporan Penanganan Kejadian Petugas Informasi dan
              Komunikasi as XLSX</v-btn
            >
            <v-btn
                outlined
                style="max-width: 100%"
                class="btn-wrap-text mt-3 text-capitalize"
                @click="downloadAs('event', 'PDF')"
            >
              Data Penanganan Kendaraan Mengalami Gangguan as PDF
            </v-btn>
            <v-btn
                outlined
                style="max-width: 100%"
                class="btn-wrap-text mt-3 text-capitalize"
                @click="downloadAs('vehicle', 'PDF')"
            >
              Laporan Penanganan Kejadian Petugas Informasi dan
              Komunikasi as PDF</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-data-table
      :headers="tableHeaders"
      :search="search"
      :items="computedTableItems"
      class="elevation-1"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20],
      }"
      :hide-default-footer="false"
    >
      <template v-slot:[`item.response_time`]="{ item }">
        <v-btn
          class="text-capitalize"
          color="#004643"
          width="150"
          height="34"
          outlined
          @click.stop="item.dialog = true"
          >Detail</v-btn
        >
        <v-dialog v-model="item.dialog" width="434">
          <v-card>
            <v-card-title>
              Waktu Response
              <v-spacer></v-spacer>
              <v-btn icon @click="item.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <h4>Waktu Informasi Diterima</h4>
              <p class="body-2 mt-1">
                {{
                  item.information_accepted_time
                    ? item?.information_accepted_time.slice(-8)
                    : "-"
                }}
              </p>
              <h4>Waktu Tiba di Lokasi</h4>
              <p class="body-2 mt-1">
                {{ item.arrival_time ? item?.arrival_time.slice(-8) : "-" }}
              </p>
              <h4>Response Time</h4>
              <p class="body-2 mt-1">
                {{ item.response_time ? item?.response_time.slice(-8) : "-" }}
              </p>
              <h4>Waktu Selesai</h4>
              <p class="body-2 mt-1">
                {{
                  item.completion_time ? item?.completion_time.slice(-8) : "-"
                }}
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.image_urls`]="{ item }">
        <v-img
          class="my-2"
          :src="item.image_urls[0]"
          max-width="100px"
          max-height="100px"
          :style="{ 'border-radius': '10px' }"
        ></v-img>
      </template>
      <template v-slot:[`item.arrow`]="{ item }">
        <router-link :to="item.href" v-if="item.href">
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
        <router-link to="#" v-else>
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.countdown`]="{ item }">
        <span :class="countdownClass(item.countdown)">{{
          item.countdown
        }}</span>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import ChartComponent from "@/components/ChartComponent.vue";
import { formatKilometer } from "@/plugins/helpers";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    ChartComponent,
  },
  methods: {
    async updateDate(dateFrom, dateTo) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      const response = await this.$axios.get(
        `/all-traffic-disturbances?from=${this.dateFrom}&to=${this.dateTo}`
      );
      this.tableItems = response.data.data.traffic_disruptions;
    },
    async downloadAs(type, format) {
      const queryParams = {
        from: this.dateFrom,
        to: this.dateTo,
      };

      try {
        const endpoint = format === "XLSX" ? `/download-traffic-disturbances/${type}` : `/download-traffic-disturbances/${type}/as-pdf`;
        const response = await this.$axios.get(
          endpoint,
          {
            params: queryParams,
          }
        );
        const mediaType = format === "XLSX" ? "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," : "data:application/pdf;base64,";
        const base64Data = format === "XLSX" ? response.data.excelData : response.data.pdfData;

        const url = mediaType + base64Data;
        const ext = format === "XLSX" ? "xlsx" : "pdf";

        const a = document.createElement("a");
        const formattedDate1 = `${this.dateFrom.slice(
          9,
          11
        )}${this.dateFrom.slice(5, 7)}${this.dateFrom.slice(0, 4)}`;
        const formattedDate2 = `${this.dateTo.slice(9, 11)}${this.dateTo.slice(
          5,
          7
        )}${this.dateTo.slice(0, 4)}`;
        const prefixName = type === 'vehicle' ? "Laporan Penanganan Kejadian Petugas Informasi dan Komunikasi" : "Data Penanganan Kendaraan Mengalami Gangguan";
        const fileName = `${prefixName}_${formattedDate1}_${formattedDate2}.${ext}`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  computed: {
    computedTableItems() {
      return this.tableItems.map((tableItem) => ({
        ...tableItem,
        location: `KM ${formatKilometer(tableItem.kilometer ?? 0)} - ${tableItem.track} - ${
          tableItem.lane
        }`,
        vehicle: `${tableItem.vehicle_type} - Gol. ${tableItem.vehicle_class_id} - ${tableItem.police_number}`,
        href: `/lalu-lintas/gangguan-lalu-lintas/${tableItem.id}`,
        dialog: false,
      }));
    },
  },
  data: () => ({
    dialog: false,
    snackbar: false,
    message: "",
    timeout: 3000,
    search: "",
    dateFrom: null,
    dateTo: null,
    title: "Kejadian Lalu Lintas",
    tableHeaders: [
      {
        text: "Waktu Kejadian",
        align: "start",
        value: "incident_time",
      },
      { text: "Lokasi Kejadian", value: "location" },
      { text: "Kendaraan", value: "vehicle" },
      { text: "Uraian Kejadian", value: "incident_description" },
      { text: "Patroli/Petugas Lalu Lintas", value: "officer" },
      { text: "Uraian Penanganan", value: "handling_description" },
      { text: "Waktu Response", value: "response_time" },
      { text: "Foto", value: "image_urls", sortable: false, filterable: false },
      {
        text: "",
        value: "arrow",
        sortable: false,
        filterable: false,
        width: "50px",
        align: "center",
      },
    ],
    tableItems: [],
    crumbs: [
      {
        text: "Layanan Lalu Lintas",
        disabled: false,
        href: "/lalu-lintas",
      },
      {
        text: "Gangguan Lalu Lintas",
        disabled: true,
        href: "/lalu-lintas/gangguan-lalu-lintas",
      },
    ],
  }),
};
</script>

<style>
.btn-wrap-text {
  height: auto !important;
  max-width: 100%;
  white-space: normal;
  &.v-btn:not(.v-btn--round).v-size--default {
    padding: 10px 16px;
  }
  .v-btn__content {
    flex: 1 0 fit-content;
  }
}
.btn-wrap-text:hover {
  height: auto !important;
  max-width: 100%;
  white-space: normal;
  &.v-btn:not(.v-btn--round).v-size--default {
    padding: 10px 16px;
  }
  .v-btn__content {
    flex: 1 0 fit-content;
  }
  color: white !important;
  background-color: #004643 !important;
}
</style>
