<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div class="mb-7">
      <title-component :title="title"/>
      <breadcrumbs-component :crumbs="crumbs"/>
    </div>
    <v-row>
      <v-col cols="6"
      >
        <category-detail-card
            :asset-category="dataResponse.asset_category"
            :asset-sub-category="dataResponse.asset_sub_category"
            :asset-advance-category="dataResponse.asset_advance_category"
            :location="formatKilometer(dataResponse.location)"
        />
      </v-col>
      <v-col cols="6"
      >
        <card-photo-group-component :images="dataResponse.images"
        />
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="4"
      >
        <description-laporan-component
            :desc_initial="dataResponse.desc_initial"
        />
      </v-col>
      <v-col cols="4"
      >
        <card-pelapor-component
            :reporter="dataResponse.initial_reporter || '-'"
        />
      </v-col>
      <v-col cols="4"
      >
        <countdown-laporan-component
            :status="dataResponse.status"
            :initial-report-time="
            getFormattedDateTime(dataResponse.initial_report_time) || '-'
          "
            :countDownTime="countdowns"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent";
import CategoryDetailCard from "@/components/CategoryDetailCard";
import CardPelaporComponent from "@/components/CardPelaporComponent";
import CountdownLaporanComponent from "@/components/CountdownLaporanComponent";
import DescriptionLaporanComponent from "@/components/DescriptionLaporanComponent";
import CardPhotoGroupComponent from "@/components/CardPhotoGroupComponent";
import * as constant from "@/constants/constant";
import { formatKilometer } from "@/plugins/helpers";

export default {
  beforeMount() {
    this.setDataReport();
  },
  methods: {
    formatKilometer,
    async setDataReport() {
      await this.$axios.get(`/initial-report/${this.$route.params.id}`).then(
          (response) => {
            console.log(response.data);
            this.images = response.data.data.images;
            this.dataResponse = response.data.data;
          },
          (error) => {
            console.log(error);
          }
      );
      this.startCountdown();
    },
    getFormattedDateTime(datetime) {
      const createdAt = new Date(datetime);
      const hours = String(createdAt.getHours()).padStart(2, "0");
      const minutes = String(createdAt.getMinutes()).padStart(2, "0");
      const date = String(createdAt.getDate()).padStart(2, "0");
      const month = String(createdAt.getMonth()).padStart(2, "0");
      return `${hours}:${minutes} - ${date}/${month}/${createdAt.getFullYear()}`;
    },
    calculateTimeDifference(initialCreatedAt, createdAt) {
      const initialDate = new Date(initialCreatedAt);
      const endDate = new Date(createdAt);

      let timeDifferenceInMillis = endDate - initialDate;

      const isNegative = timeDifferenceInMillis < 0;
      if (isNegative) {
        timeDifferenceInMillis *= -1;
      }

      const hours = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60));
      const minutes = Math.floor(
          (timeDifferenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifferenceInMillis % (1000 * 60)) / 1000);

      const formattedTime = `${
          (isNegative ? "-" : "") + String(hours).padStart(2, "0")
      }:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0"
      )}`;

      return formattedTime;
    },
    startCountdown() {
      if (
          this?.dataResponse?.asset_category_id != 3 ||
          this?.dataResponse?.asset_category != "Jalan, Irigasi, dan Jaringan"
      ) {
        this.countdowns = "-";
      }
      const createdAt = new Date(this.dataResponse.initial_report_time);
      let deadline;
      if (![6, 7, 8].includes(this.dataResponse.asset_sub_category_id)) {
        deadline =
            constant.deadlines[this.dataResponse.asset_sub_category_id] * 3600;
      } else {
        deadline =
            constant.deadlines[this.dataResponse.asset_advance_category_id] *
            3600;
      }
      setInterval(() => {
        const currentTime = new Date();
        const targetTime = new Date(createdAt.getTime() + deadline * 1000);
        const timeDiff = targetTime - currentTime;

        if (timeDiff < 0) {
          const hours = Math.floor(timeDiff / 3600000);
          const minutes = Math.floor((timeDiff % 3600000) / 60000) * -1;
          const seconds = Math.floor((timeDiff % 60000) / 1000) * -1;

          this.countdowns = `${String(hours).padStart(2, "0")}:${String(
              minutes
          ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        } else {
          const hours = Math.floor(timeDiff / 3600000);
          const minutes = Math.floor((timeDiff % 3600000) / 60000);
          const seconds = Math.floor((timeDiff % 60000) / 1000);

          this.countdowns = `${String(hours).padStart(2, "0")}:${String(
              minutes
          ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        }
      }, 1000);
    },
  },
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    CategoryDetailCard,
    DescriptionLaporanComponent,
    CountdownLaporanComponent,
    CardPelaporComponent,
    CardPhotoGroupComponent,
  },
  computed: {
    crumbs() {
      return [
        {
          text: "Layanan Pemeliharaan",
          disabled: false,
          href: "/layanan-pemeliharaan",
        },
        {
          text: `Laporan Inisiasi (${this.dataResponse['report_number']})`,
          disabled: true,
          href: `/layanan-pemeliharaan/inisiasi/${this.$route.params.id}`,
        },
      ];
    },
  },
  data: () => ({
    countdowns: "",
    idData: "",
    dataResponse: {},
    images: [],
    title: "Layanan Pemeliharaan",
  }),
};
</script>

<style></style>
